import { type Path } from './paths'
import { type LicensedEstablishmentUser } from 'src/types/api'
import * as _ from 'lodash'

const allPermissions = [
  'leUsersPermission',
  'liveAtLocationViewPermission',
  'locationsPermission',
  'patronsAccountsPermission',
  'promotionsPermission',
  'reportsFinancialPermission',
  'reportsNonFinancialPermission',
  'rewardsPermission',
  'sweepstakesPermission',
] as const

type PermissionTuple = typeof allPermissions
type Permission = PermissionTuple[number]
type UserPermissionSet = Pick<LicensedEstablishmentUser, Permission>

type AlwaysVisiblePath = 'always'
type LoginRequiredPath = 'loginRequired'
type PermissionedPath = Permission | Permission[]

export const pathPermissions: Record<
  Path,
  AlwaysVisiblePath | LoginRequiredPath | PermissionedPath
> = {
  '/': 'always',
  '/CreateAccount': 'always',
  '/Home': 'loginRequired',
  '/Development/Home': 'loginRequired',
  '/LicensedEstablishments/:id': 'leUsersPermission',
  '/LicensedEstablishmentUsers': 'leUsersPermission',
  '/LicensedEstablishmentUsers/NewLEConnectUserPage': 'leUsersPermission',
  // '/LiveAtLocation': 'liveAtLocationViewPermission',
  // '/LiveAtLocation/PatronDetails/:id/LicensedEstablishment/:licensedEstablishmentId':
  //   'liveAtLocationViewPermission',
  // '/LiveAtLocation/PatronDetails/:id': 'liveAtLocationViewPermission',
  '/Locations': 'locationsPermission',
  '/PatronAccounts': 'patronsAccountsPermission',
  '/Promotions': 'promotionsPermission',
  '/Promotions/:id': 'promotionsPermission',
  '/FlexPay': 'reportsFinancialPermission',
  '/Reports': ['reportsNonFinancialPermission', 'reportsFinancialPermission'],
  '/Reports/PatronCheckins': 'reportsNonFinancialPermission',
  '/Reports/PatronRewardRedemptions': 'reportsNonFinancialPermission',
  '/Reports/PatronList': 'reportsNonFinancialPermission',
  '/Reports/ReportsOverview': 'leUsersPermission',
  '/Reports/PatronActivity': 'reportsNonFinancialPermission',
  '/Reports/WeeklySnapshot': 'reportsFinancialPermission',
  '/Reports/WeeklySnapshotLocationSelect': 'reportsFinancialPermission',
  '/Reports/CreditsWageredByMachine': 'reportsFinancialPermission',
  '/Reports/CreditsWageredByHour': 'reportsFinancialPermission',
  '/Reports/VgtSnapshot': 'reportsFinancialPermission',
  '/Reports/VgtTrends': 'reportsFinancialPermission',
  '/Reports/CreditsWageredHourlyTrends': 'reportsFinancialPermission',
  '/Reports/TrailingNtiTrends': 'reportsFinancialPermission',
  '/Sweepstakes': 'sweepstakesPermission',
  '/Sweepstakes/:id/Overview': 'sweepstakesPermission',
  '/Sweepstakes/:id/Winners': 'sweepstakesPermission',
  '/Sweepstakes/:id/ParticipatingLocations': 'sweepstakesPermission',
  '/Sweepstakes/:id/EnrollLocations': 'sweepstakesPermission',
  '/Sweepstakes/:id/AdditionalEnrollmentDetails': 'sweepstakesPermission',
  '/Sweepstakes/:id/ReviewLocationsToEnroll': 'sweepstakesPermission',
  '/Rewards': 'rewardsPermission',
  '/RewardsProgram/:id': 'rewardsPermission',
  // '/RewardsProgram/:id/Settings': 'rewardsPermission',
  '/RewardsProgram/:id/Redemption': 'rewardsPermission',
  '/RewardsProgram/:id/ActivityLog': 'rewardsPermission',
  '/RewardsProgram/:id/RewardsCatalog/:rewardCatalogId': 'rewardsPermission',
}

const pathPermissionsAsRegExps: Array<
  [RegExp, AlwaysVisiblePath | LoginRequiredPath | PermissionedPath]
> = _.map(pathPermissions, (v, k) => {
  return [new RegExp('^' + k.replace(/:\w*id/gi, '\\d+') + '$'), v]
})

const findPathPermission = (path: string) => {
  const match = _.find(pathPermissionsAsRegExps, (x) => x[0].test(path))
  if (_.isNil(match)) {
    return null
  }
  return match[1]
}

// In practice, you can call this with an LicensedEstablishmentUser because
// UserPermissionSet is a strict subset of LicensedEstablishmentUser
export const isPathAvailable = (
  path: Path,
  permissions?: UserPermissionSet
): boolean => {
  const pathPermission = findPathPermission(path)

  if (pathPermission === 'always') {
    return true
  }

  if (!permissions) {
    return false
  }

  if (pathPermission === 'loginRequired') {
    return true
  }

  const availablePermissions = userPermissions(permissions)
  const requiredPermissions = [pathPermission].flat()
  return _.intersection(availablePermissions, requiredPermissions).length > 0
}

// Given a UserPermissionSet, build an array of permissions
function userPermissions(userPermissionSet: UserPermissionSet) {
  return allPermissions.reduce<Permission[]>(
    (m, p) => (userPermissionSet[p] ? [...m, p] : m),
    []
  )
}
